import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero/hero";
import HomeTabs from "../components/homeTabs/homeTabs";
import HomeSlider from "../components/homeSlider/homeSlider";
import HomeContact from "../components/homeContact/homeContact";

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Homepage" />
      <Hero data={data.wpPage.homepageHero} />
      <HomeTabs data={data.wpPage.homepageTabs} />
      <HomeSlider
        extraClassName="homepage-partners-slider"
        data={data.wpPage.homepagePartners}
        type="partners"
      />
      <HomeSlider
        extraClassName="homepage-clients-slider"
        data={data.wpPage.homepageClients}
        type="clients"
      />
      <HomeContact data={data.wpPage.homepageContact} />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(title: { eq: "Homepage" }) {
      id
      homepageHero {
        slide1 {
          content
          title
          link {
            text
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slide2 {
          content
          title
          link {
            text
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slide3 {
          content
          title
          link {
            text
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      homepageTabs {
        tab1 {
          title
          cases {
            case1 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case2 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case3 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case4 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case5 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case6 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case7 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case8 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
        tab2 {
          title
          cases {
            case1 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case2 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            case3 {
              title
              content
              link {
                text
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      homepageClients {
        clientsText {
          title
          subtitle
          content
        }
        clients {
          client1 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client2 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client3 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client4 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client5 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client6 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client7 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client8 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client9 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client10 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client11 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client12 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client13 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client14 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client15 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          client16 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      homepagePartners {
        partnersText {
          title
        }
        partners {
          partner1 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner2 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner3 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner4 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner5 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner6 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner7 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner8 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner9 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner10 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner11 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner12 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner13 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner14 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner15 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
          partner16 {
            name
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 270, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      homepageContact {
        contactText {
          title
          subtitle
          content
        }
      }
    }
  }
`;
